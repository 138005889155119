import './scss/site.scss';

import reportWebVitals from './reportWebVitals';
import renderOrders from "./orders/renderOrders";
import renderOrderWetstock from "./order-wetstock/renderOrderWetstock";
import renderReceiveStock from "./receive-stock/renderReceiveStock";
import renderStocktake from "./features/stocktake/renderStocktake";
import renderTankBasic from "./order-wetstock/renderTankBasic";

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

renderStocktake();
renderReceiveStock();
renderOrders();
renderOrderWetstock();
renderTankBasic();

reportWebVitals(console.log);
