import React from 'react';
import ReactDOM from 'react-dom/client';
import store from "../app/store";
import {Provider} from "react-redux";
import {useGetTanksQuery} from "../services/drystock";
import {ErrorPage} from "../common/ErrorPage";
import {TankDisplay} from "./TankDisplay";
import {Loader} from "../common/Loader";


const TankBasic = ({siteId}: { siteId: number }) => {
    const {
        data,
        isSuccess,
        isError,
        error,
        isLoading,
        isFetching
    } = useGetTanksQuery({siteId});

    if (isLoading) {
        return <Loader/>
    }

    if (isError || !data) {
        return <ErrorPage show={true}/>
    }

    return (
        <div className='d-flex m-n3'>
            {data.map((tank) =>
                    <div className='d-flex m-3'>
                        <div className='align-self-center'>
                            <TankDisplay height={200} width={50} volume={tank.volume} capacity={tank.capacity} water={0}/>

                        </div>
                        <div className="ml-2 align-self-center">
                            <h5>{tank.description}</h5>
                            <dl className='m-0'>
                                <dt>Grade</dt>
                                <dd>{tank.gradeName}</dd>
                                <dt>Capacity</dt>
                                <dd>{tank.capacity}</dd>
                                <dt>Volume</dt>
                                <dd>{tank.volume}</dd>
                            </dl>
                        </div>
                    </div>
            )}
        </div>
    )
}


export default () => {
    const containers = document.getElementsByClassName('tank-basic');

    for (const container of containers) {

        const root = ReactDOM.createRoot(container)

        const siteId = container.getAttribute('data-site-id');

        if (siteId != null) {

            root.render(<React.StrictMode>
                <Provider store={store}>
                    <TankBasic siteId={+siteId}/>
                </Provider>
            </React.StrictMode>)
        }

    }
}
;